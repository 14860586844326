import React from 'react';
import { Spin } from 'antd';
import styles from './index.module.less'
// eslint-disable-next-line import/no-anonymous-default-export
export default ()=>{
  return (
    <div className={styles.spinWrap}>
      <Spin size="large">
        <div/>
      </Spin>
    </div>
  );
}
