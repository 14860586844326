import {lazy} from "react";
export const route = [
    {
        title:"登录",
        path: '/',
        component: lazy(() => import('@/pages/login')),
    },
    {
        title:"登录",
        path: '/login',
        component: lazy(() => import('@/pages/login')),
    },
    {
        title:"小鹰",
        path: '/enterpriseAI',
        component: lazy(() => import('@/pages/enterpriseAI')),
    },
    {
        title:"客服管理",
        key:"customerServiceManaging",
        path: "/customerServiceManaging",
        component: lazy(() => import('@/layout')),
        icon: 'customerServiceManagingIcon.png',
        sideMenu:true,
        children: [
            {
                title:"客服管理",
                key:"customerServiceManaging",
                path: "/customerServiceManaging",
                component:lazy(()=>import("@/pages/customerServiceManaging")),
            },
            {
                title:"接待人员",
                key:"receptionPersonnel",
                path: "/customerServiceManaging/receptionPersonnel",
                component:lazy(()=>import("@/pages/customerServiceManaging/receptionPersonnel")),
            }
        ]
    },
    {
        title:"客服日志",
        key:"customerServiceLog",
        path: "/customerServiceLog",
        component: lazy(() => import('@/layout')),
        icon: 'customerServiceLogIcon.png',
        sideMenu:true,
        children: [
            {
                title:"客服日志",
                key:"customerServiceLog",
                path: "/customerServiceLog",
                component:lazy(()=>import("@/pages/customerServiceLog")),
            },
        ]
    },
    {
        title:"订单管理",
        key:"orderManagement",
        path: "/orderManagement",
        component: lazy(() => import('@/layout')),
        icon: 'orderManagementIcon.png',
        sideMenu:true,
        children: [
            {
                title:"订单管理",
                key:"orderManagement",
                path: "/orderManagement",
                component:lazy(()=>import("@/pages/orderManagement")),
            },
        ]
    },
    {
        title:"激活码管理",
        key:"activationCode",
        path: "/activationCode",
        component: lazy(() => import('@/layout')),
        icon: 'activationCodeIcon.png',
        sideMenu:true,
        children: [
            {
                title:"激活码管理",
                key:"activationCode",
                path: "/activationCode",
                component:lazy(()=>import("@/pages/activationCode")),
            },
        ]
    },
    {
        title:"管理员转移",
        key:"administratorTransfer",
        path: "/administratorTransfer",
        component: lazy(() => import('@/layout')),
        icon: 'administratorTransferIcon.png',
        sideMenu:true,
        children: [
            {
                title:"管理员转移",
                key:"administratorTransfer",
                path: "/administratorTransfer",
                component:lazy(()=>import("@/pages/administratorTransfer")),
            },
        ]
    },
    {
        title:"企业信息",
        key:"enterpriseInformation",
        path: "/enterpriseInformation",
        component: lazy(() => import('@/layout')),
        icon: 'enterpriseInformationIcon.png',
        sideMenu:true,
        children: [
            {
                title:"企业信息",
                key:"企业信息",
                path: "/enterpriseInformation",
                component:lazy(()=>import("@/pages/enterpriseInformation")),
            },
        ]
    },
]

