import React, { Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
import {route} from "@/router/route";
import RoutingTransition from '../components/RoutingTransition/index'
const syncRouter = (table) => {
    let mRouteTable = []
    table.forEach(route => {
        mRouteTable.push({
            path: route.path,
            element: route.component ? (
                <Suspense fallback={ <RoutingTransition /> }>
                    <route.component/>
                </Suspense>
            ) : undefined,
            children: route.children && syncRouter(route.children)
        })
    })
    return mRouteTable
}

// 直接暴露成一个组件调用
export default () => useRoutes(syncRouter(route))

